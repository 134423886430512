<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Diagnose - Overig</h1>
        <div class="row">
          <p>
            Onderstaand dashboard geeft achtergrondinformatie weer als onderdeel van de levenspadfase Diagnose. Het doel
            van dit overzicht is om inzicht te geven in de demografische kenmerken van mensen met dementie. Het overzicht
            wordt gegeven met zowel landelijke als regionale cijfers op het niveau van zorgkantoorregio’s en
            Dementienetwerken.
          </p>
        </div>
      </div>
    </div>



    <div class="row" v-if="selectedLevel !== 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="`Migratieachtergrond mensen met dementie in ${selectedYear}`"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="regionaam_migratieachtergrond_alle" :y="percentage_migratieachtergrond_alle.map(value => `${value}%`)"
          :xtitle="'Regio naam'" :ytitle="'Percentage'" :tableHeight="selectedLevel === 'dementienetwerk' ? 1700 : 900">
          <template slot="card-header">Migratieachtergrond mensen met dementie in {{ selectedYear }}</template>
          <div slot="card-body">
            In onderstaand diagram is te zien hoe het geselecteerde zorgkantoor of Dementienetwerk zich verhoudt tot het
            landelijk gemiddelde op het gebied van migratieachtergrond.
            <stacked-bar-chart-colors v-if="load.graphTwo" :ytitle="'% met migratieachtergrond'"
              :names="['Migratieachtergrond']" :x="[zorgkantoorregionaam]" :y="[migratieachtergrond]" :showlegend="false"
              :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Verdeling leeftijd mensen met dementie'"
          :hovertext="'Leeftijd op 1 januari. Alleen observaties voor mensen van 39 jaar (die worden 40 in kalenderjaar) of ouder behouden.'"
          :x="leeftijd_jaar_landelijk" :y="leeftijdscategorie_landelijk"
          :z="percentage_leeftijd.map(value => `${value}%`)" :xtitle="'Jaar'" :ytitle="'Leeftijds categorie'"
          :ztitle="'Percentage'" :tableHeight="600">
          <template slot="card-header">Verdeling leeftijd mensen met dementie</template>
          <div slot="card-body">
            In onderstaande grafiek is het landelijk gemiddelde weergegeven wat betreft de leeftijdsverdeling van mensen
            met dementie.
            <stacked-bar-chart-colors-multi v-if="load.graphThree" :isLandelijk='false' :height="360" :name4="'< 65 jaar'"
              :x4="zorgkantoorregionaam_65" :xx4="jaar_landel_65" :y4="leeftijd_65" :name3="'66 - 79 jaar'"
              :x3="zorgkantoorregionaam_66" :xx3="jaar_landel_66" :y3="leeftijd_66" :name2="'80 - 89 jaar'"
              :x2="zorgkantoorregionaam_80" :xx2="jaar_landel_80" :y2="leeftijd_80" :name1="'90 jaar en ouder'"
              :x1="zorgkantoorregionaam_90" :xx1="jaar_landel_90" :y1="leeftijd_90" />
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Migratieachtergrond mensen met dementie'"
          :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
          :x="ma_jaar_landelijk" :y="migratieachtergrond_landelijk_table.map(value => `${value}%`)" :xtitle="'Jaar'"
          :ytitle="'Percentage'" :tableHeight="300">
          <template slot="card-header">Migratieachtergrond mensen met dementie</template>
          <div slot="card-body">
            Onderstaand diagram toont het landelijk gemiddelde qua migratieachtergrond.
            <stacked-bar-chart-colors v-if="load.graphFour" :ytitle="'% met migratieachtergrond'" :isLandelijk="false"
              :xtitle="'Jaar'" :x="[ma_jaar_landelijk]" :y="[migratieachtergrond_landelijk]" :showlegend="false"
              :ticksuffix="'%'" />
          </div>
        </card>
      </div>
    </div>

    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo" />
  </div>
</template>

<script>

import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import Card from '../components/Card'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Diagnose - Overig',
  },
  components: { Card, StackedBarChartColors, Footer, StackedBarChartColorsMulti },
  data() {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionaam_65: [],
      leeftijd_65: [],
      zorgkantoorregionaam_66: [],
      leeftijd_66: [],
      zorgkantoorregionaam_80: [],
      leeftijd_80: [],
      zorgkantoorregionaam_90: [],
      leeftijd_90: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false
      },
      geenmigratieachtergrond: [],
      zorgkantoorregionaam: [],
      migratieachtergrond: [],
      ma_jaar: [],
      top5mignaam: [],
      top5migperc: [],
      top5jongnaam: [],
      top5jongperc: [],
      jaar_landel_65: [],
      jaar_landel_66: [],
      jaar_landel_80: [],
      jaar_landel_90: [],
      leeftijd_groep: [],
      regionaam_leeftijd_alle: [],
      percentage_leeftijd_alle: [],
      regionaam_migratieachtergrond_alle: [],
      percentage_migratieachtergrond_alle: [],
      leeftijd_jaar_landelijk: [],
      leeftijdscategorie_landelijk: [],
      percentage_leeftijd: [],
      migratieachtergrond_landelijk: [],
      migratieachtergrond_landelijk_table: [],
      ma_jaar_landelijk: [],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
    ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData(level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_leeftijd/get_data/?jaar=${this.$store.state.selectedYear}&dementie=True`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage - a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.zorgkantoorregionaam_65 = response.data.filter(x => x.leeftijdscategorie == '<65').map(d => d.zorgkantoorregionaam)
            this.leeftijd_65 = response.data.filter(x => x.leeftijdscategorie == '<65').map(d => (d.percentage ))
            this.zorgkantoorregionaam_66 = response.data.filter(x => x.leeftijdscategorie == '65-79').map(d => d.zorgkantoorregionaam)
            this.leeftijd_66 = response.data.filter(x => x.leeftijdscategorie == '65-79').map(d => (d.percentage ))
            this.zorgkantoorregionaam_80 = response.data.filter(x => x.leeftijdscategorie == '80-89').map(d => d.zorgkantoorregionaam)
            this.leeftijd_80 = response.data.filter(x => x.leeftijdscategorie == '80-89').map(d => (d.percentage ))
            this.zorgkantoorregionaam_90 = response.data.filter(x => x.leeftijdscategorie == '90+').map(d => d.zorgkantoorregionaam)
            this.leeftijd_90 = response.data.filter(x => x.leeftijdscategorie == '90+').map(d => (d.percentage ))
            this.top5jongnaam = response.data.filter(x => x.leeftijdscategorie == '<65').sort((a, b) => a.percentage - b.percentage).map(d => d.zorgkantoorregionaam).slice(-5)
            this.top5jongperc = response.data.filter(x => x.leeftijdscategorie == '<65').sort((a, b) => a.percentage - b.percentage).map(d => (d.percentage)).slice(-5)

            response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam)
            this.leeftijd_groep = response.data.map(d => d.leeftijdscategorie)
            this.regionaam_leeftijd_alle = response.data.map(d => d.zorgkantoorregionaam)
            this.percentage_leeftijd_alle = response.data.map(d => (d.percentage ).toFixed(1))
            this.load.graphOne = true
          }
        ),
          this.$http.get(`/api/zorgkantoor_migratieachtergrond/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a, b) => b.percentage - a.percentage)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
              this.migratieachtergrond = response.data.map(d => (d.percentage ))
              response.data.sort((a, b) => a.percentage - b.percentage)
              this.top5mignaam = response.data.map(d => d.zorgkantoorregionaam).slice(-5)
              this.top5migperc = response.data.map(d => (d.percentage )).slice(-5)

              response.data.sort((a, b) => a.zorgkantoorregionaam - b.zorgkantoorregionaam)
              this.regionaam_migratieachtergrond_alle = response.data.map(d => d.zorgkantoorregionaam)
              this.percentage_migratieachtergrond_alle = response.data.map(d => (d.percentage ).toFixed(1))
              this.load.graphTwo = true
            }
          )
      }
      else if (level === 'landelijk') {
        this.$http.get(`/api/zorgkantoor_leeftijd/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a, b) => a.jaar - b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
            this.jaar_landel_65 = response.data.filter(x => x.leeftijdscategorie == "<65").map(d => d.jaar)
            this.zorgkantoorregionaam_65 = response.data.filter(x => x.leeftijdscategorie == '<65').map(d => d.zorgkantoorregionaam)
            this.leeftijd_65 = response.data.filter(x => x.leeftijdscategorie == '<65').map(d => (d.percentage))
            this.jaar_landel_66 = response.data.filter(x => x.leeftijdscategorie == "65-79").map(d => d.jaar)
            this.zorgkantoorregionaam_66 = response.data.filter(x => x.leeftijdscategorie == '65-79').map(d => d.zorgkantoorregionaam)
            this.leeftijd_66 = response.data.filter(x => x.leeftijdscategorie == '65-79').map(d => (d.percentage))
            this.jaar_landel_80 = response.data.filter(x => x.leeftijdscategorie == "80-89").map(d => d.jaar)
            this.zorgkantoorregionaam_80 = response.data.filter(x => x.leeftijdscategorie == '80-89').map(d => d.zorgkantoorregionaam)
            this.leeftijd_80 = response.data.filter(x => x.leeftijdscategorie == '80-89').map(d => (d.percentage))
            this.zorgkantoorregionaam_90 = response.data.filter(x => x.leeftijdscategorie == '90+').map(d => d.zorgkantoorregionaam)
            this.jaar_landel_90 = response.data.filter(x => x.leeftijdscategorie == "90+").map(d => d.jaar)
            this.leeftijd_90 = response.data.filter(x => x.leeftijdscategorie == '90+').map(d => (d.percentage))

            this.leeftijd_jaar_landelijk = response.data.map(d => d.jaar)
            this.leeftijdscategorie_landelijk = response.data.map(d => d.leeftijdscategorie)
            this.percentage_leeftijd = response.data.map(d => (d.percentage ).toFixed(1))
            this.load.graphThree = true
          }
        ),
          this.$http.get(`/api/zorgkantoor_migratieachtergrond/get_data/?landelijk=True`).then(
            (response) => {
              response.data.sort((a, b) => a.jaar - b.jaar)
              response.data = response.data.filter(x => x.zorgkantoorregionaam !== 'Missing')
              this.ma_jaar_landelijk = response.data.map(d => d.jaar)
              this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
              this.migratieachtergrond_landelijk = response.data.map(d => (d.percentage))
              this.migratieachtergrond_landelijk_table = response.data.map(d => (d.percentage ).toFixed(1))
              this.load.graphFour = true
            }
          )
      }
      else {
        this.$http.get(`/api/dementienetwerk_leeftijd/get_data/?jaar=${this.$store.state.selectedYear}&dementie=True`).then(
          (response) => {
            response.data.sort((a, b) => b.percentage - a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !== 'Missing')
            this.zorgkantoorregionaam_65 = response.data.filter(x => x.leeftijdscategorie == '<65').map(d => d.dementienetwerknaam)
            this.leeftijd_65 = response.data.filter(x => x.leeftijdscategorie == '<65').map(d => (d.percentage ))
            this.zorgkantoorregionaam_66 = response.data.filter(x => x.leeftijdscategorie == '65-79').map(d => d.dementienetwerknaam)
            this.leeftijd_66 = response.data.filter(x => x.leeftijdscategorie == '65-79').map(d => (d.percentage ))
            this.zorgkantoorregionaam_80 = response.data.filter(x => x.leeftijdscategorie == '80-89').map(d => d.dementienetwerknaam)
            this.leeftijd_80 = response.data.filter(x => x.leeftijdscategorie == '80-89').map(d => (d.percentage ))
            this.zorgkantoorregionaam_90 = response.data.filter(x => x.leeftijdscategorie == '90+').map(d => d.dementienetwerknaam)
            this.leeftijd_90 = response.data.filter(x => x.leeftijdscategorie == '90+').map(d => (d.percentage ))
            this.top5jongnaam = response.data.filter(x => x.leeftijdscategorie == '<65').sort((a, b) => a.percentage - b.percentage).map(d => d.dementienetwerknaam).slice(-5)
            this.top5jongperc = response.data.filter(x => x.leeftijdscategorie == '<65').sort((a, b) => a.percentage - b.percentage).map(d => (d.percentage)).slice(-5)

            response.data.sort((a, b) => a.dementienetwerknaam - b.dementienetwerknaam)
            this.leeftijd_groep = response.data.map(d => d.leeftijdscategorie)
            this.regionaam_leeftijd_alle = response.data.map(d => d.dementienetwerknaam)
            this.percentage_leeftijd_alle = response.data.map(d => (d.percentage ).toFixed(1))
            this.load.graphOne = true
          }
        ),
          this.$http.get(`/api/dementienetwerk_migratieachtergrond/get_data/?jaar=${this.$store.state.selectedYear}`).then(
            (response) => {
              response.data.sort((a, b) => b.percentage - a.percentage)
              response.data = response.data.filter(x => x.dementienetwerknaam !== 'Missing')
              this.zorgkantoorregionaam = response.data.map(d => d.dementienetwerknaam)
              this.migratieachtergrond = response.data.map(d => (d.percentage ))
              response.data.sort((a, b) => a.percentage - b.percentage)
              this.top5mignaam = response.data.map(d => d.dementienetwerknaam).slice(-5)
              this.top5migperc = response.data.map(d => (d.percentage )).slice(-5)

              response.data.sort((a, b) => a.dementienetwerknaam - b.dementienetwerknaam)
              this.regionaam_migratieachtergrond_alle = response.data.map(d => d.dementienetwerknaam)
              this.percentage_migratieachtergrond_alle = response.data.map(d => (d.percentage).toFixed(1))
              this.load.graphTwo = true
            }
          )
      }
    }
  },
  mounted() {
    this.loadAllData(this.selectedLevel)
  }
}

</script>

<style scoped>
.intro-section-title {
  font-weight: bold;
  margin-bottom: 2rem;
}
</style>